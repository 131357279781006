import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Typography,
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PrintIcon from '@material-ui/icons/Print';
import ItemsTable from './ItemsTable';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  headerText: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  flash: {
    background: theme.palette.primary.main,
    '& p': {
      color: theme.palette.primary.contrastText
    }
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  },
  button: {
    textAlign: 'center',
    marginRight: theme.spacing(1)
  },
  fullWidth: {
    width: '100%'
  }
}));

const Box = props => {
  const { box, flash, onClose } = props;

  const [isPanelOpen, setPanelOpen] = useState(box.state !== 'open');
  const ref = useRef();
  const classes = useStyles();

  useEffect(() => {
    if (flash === box.id)
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [flash, box]);

  const handleClose = e => {
    console.log(box.name, 'clicked');

    e.preventDefault();
    e.stopPropagation();
    setPanelOpen(false);
    setTimeout(() => onClose(box.id), 300);
    return;
  };

  const handleChange = () => {
    setPanelOpen(!isPanelOpen);
  };

  const getBoxColor = group =>
    ({
      'Destroy at store': 'red',
      'Donate at store': 'green',
      'Hold at reclaim': 'magenta',
      'Return to reclaim': 'blue'
    }[group]);

  return (
    <ExpansionPanel
      className={flash === box.id ? classes.flash : ''}
      expanded={isPanelOpen}
      onChange={handleChange}
      ref={ref}
    >
      <ExpansionPanelSummary
        aria-controls={box.name}
        expandIcon={<ExpandMoreIcon />}
        id={box.id}
        style={{
          borderTop: `3px solid ${getBoxColor(box.group)}`,
          borderRadius: '5px'
        }}
      >
        <Grid
          className={classes.header}
          container
          direction="row"
          spacing={3}
        >
          <Grid
            className={classes.headerText}
            item
          >
            <Typography className={classes.heading}>{box.name}</Typography>
          </Grid>
          <Grid item>
            {props.recall.type !== 'simple' && (
              <>
                <Button
                  className={classes.button}
                  color="secondary"
                  variant="contained"
                >
                  <PrintIcon /> Shipping Label
                </Button>
                <Button
                  className={classes.button}
                  color="secondary"
                  variant="contained"
                >
                  <PrintIcon /> Packing Slip
                </Button>
              </>
            )}
            {box.status !== 'closed' && (
              <Button
                className={classes.button}
                color="primary"
                onClick={handleClose}
                variant="contained"
              >
                Close box
              </Button>
            )}
          </Grid>
        </Grid>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.fullWidth}>
        <ItemsTable items={box.items || []} />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

Box.propTypes = {
  className: PropTypes.string
};

export default Box;
