import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardContent } from '@material-ui/core';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/luxon';

const useStyles = makeStyles(theme => ({
  item: {
    marginTop: '1rem',
    width: '100%'
  },
  sectionWrap: {
    display: 'flex',
    justifyContent: 'center'
  },
  section: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    height: '150px',
    justifyContent: 'space-around'
  },
  card: {
    marginBottom: theme.spacing(4)
  },
  paper: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(4)
  }
}));
const RecallDuration = ({ values, onChange }) => {
  const classes = useStyles();
  const [selectedDate, handleDateChange] = useState(new Date());
  const [selectedEndDate, handleEndDateChange] = useState(
    new Date(new Date().setDate(new Date().getDate() + 10))
  );

  return (
    <Grid>
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.sectionWrap}>
            <div className={classes.section}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  label="Start"
                  onChange={handleDateChange}
                  value={selectedDate}
                />
                <DateTimePicker
                  inputVariant="outlined"
                  label="End"
                  onChange={handleEndDateChange}
                  value={selectedEndDate}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default RecallDuration;
