import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  CardMedia,
  Grid
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: { width: '100%' },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  nameImage: {
    display: 'flex',
    alignItems: 'center'
  },
  media: {
    backgroundSize: 'contain',
    width: 50,
    height: 50,
    marginRight: theme.spacing(4)
  }
}));

const ItemsTable = props => {
  const { className, items, ...rest } = props;

  const classes = useStyles();

  if (items.length === 0)
    return (
      <Typography
        align="center"
        variant="caption"
      >
        Empty!
      </Typography>
    );

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>name</TableCell>
                  <TableCell>UPC</TableCell>
                  <TableCell>Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map(item => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={item.upc}
                  >
                    <TableCell>
                      <Grid className={classes.nameImage}>
                        <CardMedia
                          className={classes.media}
                          image={item.image}
                        />
                        <Typography> {item.name}</Typography>
                      </Grid>
                    </TableCell>
                    <TableCell>{item.upc}</TableCell>
                    <TableCell>{item.quantity || 0}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

export default ItemsTable;
