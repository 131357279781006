import React, { useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  Card,
  CardContent,
  Chip,
  Button
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(theme => ({
  item: {
    marginTop: '1rem',
    width: '100%'
  },
  sectionWrap: {
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  section: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  card: {
    marginBottom: theme.spacing(1)
  },
  paper: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(4)
  }
}));
const RecallAddStore = ({ values, updateValues }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState('');
  const [exclusion, setExclusion] = useState('');

  const divisionStores = useMemo(
    () => [
      { title: 'Western division', nos: 120 },
      { title: 'Southern division', nos: 120 },
      { title: 'Eastern division', nos: 120 },
      { title: 'Store 1' },
      { title: 'Store 2' },
      { title: 'Store 3' },
      { title: 'Store 4' },
      { title: 'Store 5' },
      { title: 'Store 6' },
      { title: 'Store 7' },
      { title: 'Store 8' }
    ],
    []
  );

  const stores = useMemo(() => divisionStores.filter(store => !store.nos), [
    divisionStores
  ]);

  const addItem = () => {
    updateValues('stores', [...(values.stores || []), selected]);
    setSelected('');
  };

  const addExclusion = () => {
    updateValues('stores_excluded', [
      ...(values.stores_excluded || []),
      exclusion
    ]);
    setExclusion('');
  };

  const handleExclusionsDelete = index => () => {
    const stores = [...values.stores_excluded];
    stores.splice(index, 1);
    updateValues('stores_excluded', [...stores]);
  };

  const handleDelete = index => () => {
    const stores = [...values.stores];
    stores.splice(index, 1);
    updateValues('stores', [...stores]);
  };

  return (
    <Grid>
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.sectionWrap}>
            <div className={classes.section}>
              <Autocomplete
                getOptionLabel={option => option.title || ''}
                onChange={(event, newValue) => {
                  setSelected(newValue);
                }}
                options={divisionStores}
                renderInput={params => (
                  <TextField
                    className={classes.item}
                    label="Add divisions/stores"
                    variant="outlined"
                    {...params}
                  />
                )}
                style={{ width: 300 }}
                value={selected}
              />

              <Button
                className={classes.button}
                color="secondary"
                onClick={addItem}
                variant="contained"
              >
                Add
              </Button>
            </div>
          </div>
          <Grid style={{ marginTop: '12px' }}>
            {values.stores &&
              values.stores.map((store, i) => (
                <Chip
                  color="secondary"
                  key={store.title}
                  label={`${store.title} ${
                    store.nos ? '(' + store.nos + ' stores)' : ''
                  }`}
                  onDelete={handleDelete(i)}
                  size="medium"
                  style={{ marginRight: '4px' }}
                />
              ))}
          </Grid>
        </CardContent>
      </Card>
      {values.stores && values.stores.length > 0 && (
        <Card className={classes.card}>
          <CardContent>
            <div className={classes.sectionWrap}>
              <div className={classes.section}>
                <Autocomplete
                  getOptionLabel={option => option.title || ''}
                  onChange={(event, newValue) => {
                    setExclusion(newValue);
                  }}
                  options={stores}
                  renderInput={params => (
                    <TextField
                      className={classes.item}
                      error
                      label="Exclude divisions/stores"
                      variant="outlined"
                      {...params}
                    />
                  )}
                  style={{ width: 300 }}
                  value={exclusion}
                />

                <Button
                  className={classes.button}
                  color="secondary"
                  onClick={addExclusion}
                  variant="contained"
                >
                  Add
                </Button>
              </div>
            </div>

            <Grid style={{ marginTop: '12px' }}>
              {values.stores_excluded &&
                values.stores_excluded.map((store, i) => (
                  <Chip
                    color="primary"
                    key={store.title}
                    label={`${store.title} ${
                      store.nos ? '(' + store.nos + ' stores)' : ''
                    }`}
                    onDelete={handleExclusionsDelete(i)}
                    size="medium"
                    style={{ marginRight: '4px', backgroundColor: '#e53835' }}
                  />
                ))}
            </Grid>
          </CardContent>
        </Card>
      )}
    </Grid>
  );
};

export default RecallAddStore;
