import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Card, CardContent } from '@material-ui/core';
import NoticeForm from './NoticeForm';
import RecallClassForm from './RecallClassForm';
import RecallDispositionForm from './RecallDispositionForm';
import RecallAddItem from './RecallAddItem';
import RecallAddStore from './RecallAddStore';
import RecallDuration from './RecallDuration';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

function getSteps() {
  return [
    'Fill the recall notice.',
    'What class is this recall?',
    'How do you want items from this recall handled?',
    'What items are being recalled?',
    'What stores does this recall apply to?',
    'When is this recall active?'
  ];
}

const Content = ({ stepIndex, ...rest }) => {
  switch (stepIndex) {
    case 0:
      return <NoticeForm {...rest} />;
    case 1:
      return <RecallClassForm {...rest} />;
    case 2:
      return <RecallDispositionForm {...rest} />;
    case 3:
      return <RecallAddItem {...rest} />;
    case 4:
      return <RecallAddStore {...rest} />;

    case 5:
      return <RecallDuration {...rest} />;

    default:
      return null;
  }
};
const Form = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [values, setValues] = useState({
    instructions: `1. Immediately remove this product from your shelves and 
  stock areas, record and enter your item counts into the 
   recall tracking system at www.recalls123.com
2. Log into the system using AWG + Your Store Number (i.e., 
 AWG1234) and your equity group number as the password
3. Follow the onscreen instructions to complete the process
4. If you have no inventory or do not carry this item, please 
  enter 0 in the quantity field(s)`
  });
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleChange = event => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const updateValues = (key, value) => {
    setValues({ ...values, [key]: value });
  };

  return (
    <div className={classes.root}>
      <Card style={{ marginBottom: '8px' }}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
        >
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Card>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Card>
              <CardContent>
                <Typography
                  align="center"
                  variant="body1"
                >
                  Your recall has been saved.
                </Typography>
              </CardContent>
            </Card>
          </div>
        ) : (
          <div>
            <Content
              onChange={handleChange}
              stepIndex={activeStep}
              updateValues={updateValues}
              values={values}
            />
            <div>
              <Button
                className={classes.backButton}
                disabled={activeStep === 0}
                onClick={handleBack}
              >
                Back
              </Button>
              <Button
                color="primary"
                onClick={handleNext}
                variant="contained"
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Form;
