import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  Card,
  CardContent,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CardMedia,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormGroup,
  Box,
  MenuItem
} from '@material-ui/core';

import mockData from '../RecallsList/data';

const useStyles = makeStyles(theme => ({
  item: {
    marginTop: '1rem',
    width: '300px'
  },
  popupItem: {
    marginTop: '1rem',
    minWidth: '200px'
  },
  sectionWrap: {
    display: 'flex',
    justifyContent: 'center'
  },
  section: {
    width: '60%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  card: {
    marginBottom: theme.spacing(4)
  },
  paper: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(4)
  },
  nameImage: {
    display: 'flex',
    alignItems: 'center'
  },
  media: {
    backgroundSize: 'contain',
    width: 50,
    height: 50
  },
  popupMedia: {
    backgroundSize: 'contain',
    width: 80,
    height: 80,
    margin: theme.spacing(2)
  },
  table: {
    margin: theme.spacing(4)
  }
}));

const itemDefault = {
  name: '',
  description: '',
  unitsPerPack: '',
  department: '',
  manufacturer: '',
  retail: '',
  number: ''
};

const RecallAddItem = ({ values, updateValues }) => {
  const classes = useStyles();
  const [upc, setUPC] = useState('');
  const [item, setItem] = useState({ ...itemDefault });
  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleUPCChange = event => {
    setUPC(event.target.value);
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') handleClick();
  };

  const handleClick = () => {
    const product = mockData[0].items.find(item => item.upc === upc);
    if (product) {
      setItem({ ...product });
    }
    setDialogOpen(true);
  };

  const handleChange = key => event => {
    setItem({ ...item, [key]: event.target.value });
  };

  const addItem = () => {
    console.log(item);
    if (item.id) {
      updateValues('items', [...(values.items || []), item]);

      setItem({ ...itemDefault });
      setUPC('');
      setDialogOpen(false);
    } else {
      setItem({ ...item, id: +new Date(), upc });
    }
  };

  return (
    <Grid>
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.sectionWrap}>
            <div className={classes.section}>
              <TextField
                className={classes.item}
                label="UPC/Barcode"
                onChange={handleUPCChange}
                onKeyPress={handleKeyPress}
                value={upc}
                variant="outlined"
              />
              <Button
                className={classes.button}
                color="secondary"
                onClick={handleClick}
                variant="contained"
              >
                Add
              </Button>
            </div>
          </div>
          {values.items && values.items.length > 0 && (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>UPC</TableCell>
                  <TableCell>Specs</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.items.map(item => (
                  <TableRow
                    hover
                    key={item.upc}
                  >
                    <TableCell>
                      <Grid className={classes.nameImage}>
                        <CardMedia
                          className={classes.media}
                          image={item.image}
                        />
                        <Typography> {item.name}</Typography>
                      </Grid>
                    </TableCell>
                    <TableCell>{item.upc}</TableCell>
                    <TableCell>
                      {item.specifier}: {item.values}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Card>

      <Dialog open={isDialogOpen}>
        <DialogTitle>
          {item.id ? 'Product information' : 'Add new product'}
        </DialogTitle>
        <DialogContent>
          <Box
            minHeight={300}
            minWidth={500}
            padding={2}
          >
            <Typography
              align="center"
              gutterBottom
              variant="h4"
            />
            {item.id && (
              <>
                <Card style={{ display: 'flex' }}>
                  <CardMedia
                    className={classes.popupMedia}
                    image={item.image}
                  />
                  <CardContent>
                    <Typography>{item.name}</Typography>
                  </CardContent>
                </Card>

                <Grid
                  style={{ display: 'flex', justifyContent: 'space-around' }}
                >
                  <TextField
                    className={classes.popupItem}
                    label="Specifier"
                    onChange={handleChange('specifier')}
                    select
                    value={item.specifier}
                    variant="outlined"
                  >
                    <MenuItem value="LOT">LOT</MenuItem>
                    <MenuItem value="IMEI">IMEI</MenuItem>
                  </TextField>
                  <TextField
                    className={classes.popupItem}
                    label="Values"
                    onChange={handleChange('values')}
                    value={item.values}
                    variant="outlined"
                  />
                </Grid>
              </>
            )}
            {!item.id && (
              <Grid className={classes.boxFormInline}>
                <FormGroup>
                  <TextField
                    className={classes.popupItem}
                    label="Item number"
                    onChange={handleChange('number')}
                    value={item.number}
                    variant="outlined"
                  />
                </FormGroup>
                <FormGroup>
                  <TextField
                    className={classes.popupItem}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      multiple: true
                    }}
                    label="Item image"
                    type="file"
                    variant="outlined"
                  />
                </FormGroup>
                <FormGroup>
                  <TextField
                    className={classes.popupItem}
                    label="name"
                    onChange={handleChange('name')}
                    value={item.name}
                    variant="outlined"
                  />
                </FormGroup>
                <FormGroup>
                  <TextField
                    className={classes.popupItem}
                    label="description"
                    multiline
                    onChange={handleChange('description')}
                    rows={3}
                    value={item.description}
                    variant="outlined"
                  />
                </FormGroup>
                <FormGroup>
                  <TextField
                    className={classes.popupItem}
                    label="Manufacturer"
                    onChange={handleChange('manufacturer')}
                    value={item.manufacturer}
                    variant="outlined"
                  />
                </FormGroup>
                <FormGroup>
                  <TextField
                    className={classes.popupItem}
                    label="Retail"
                    onChange={handleChange('retail')}
                    value={item.retail}
                    variant="outlined"
                  />
                </FormGroup>
                <FormGroup>
                  <TextField
                    className={classes.popupItem}
                    label="Units per pack"
                    onChange={handleChange('unitsPerPack')}
                    value={item.unitsPerPack}
                    variant="outlined"
                  />
                </FormGroup>
                <FormGroup>
                  <TextField
                    className={classes.popupItem}
                    label="Department"
                    onChange={handleChange('department')}
                    select
                    value={item.department}
                    variant="outlined"
                  >
                    <MenuItem>Food</MenuItem>
                  </TextField>
                </FormGroup>
              </Grid>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogOpen(false)}
            variant="contained"
          >
            Back
          </Button>
          <Button
            color="primary"
            onClick={addItem}
            variant="contained"
          >
            Next
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default RecallAddItem;
