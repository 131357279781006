import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  Card,
  CardContent,
  MenuItem,
  Paper,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormGroup,
  Button,
  Box
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  item: {
    marginTop: '1.2rem',
    width: '100%'
  },
  sectionWrap: {
    display: 'flex',
    justifyContent: 'center'
  },
  section: {
    width: '60%'
  },
  card: {
    marginBottom: theme.spacing(4)
  },
  paper: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(4)
  }
}));
const RecallDispositionForm = ({ values, onChange }) => {
  const classes = useStyles();

  const [isAddressFormOpen, setAddressFormOpen] = useState(false);

  useEffect(() => {
    values.returnTo === 'new' && setAddressFormOpen(true);
  }, [values.returnTo]);

  const getSummary = () =>
    values.disposition.includes('Return')
      ? 'Item should be return to the reclaim center.'
      : 'Item should be immediately remove from it\'s packaging and thrown in the trash';
          
  return (
    <Grid>
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.sectionWrap}>
            <div className={classes.section}>
              <TextField
                className={classes.item}
                fullWidth
                label="Select the disposition"
                name="disposition"
                onChange={onChange}
                select
                value={values.disposition}
                variant="outlined"
              >
                <MenuItem value="Destroy at store">Destroy at store</MenuItem>
                <MenuItem value="Return to reclaim">Return to reclaim</MenuItem>
              </TextField>

              {values.disposition && (
                <>
                  <Paper className={classes.paper}>
                    <Typography align="center">{values.disposition}</Typography>
                    <Typography
                      align="center"
                      style={{ marginTop: '8px' }}
                      variant="body1"
                    >
                      {getSummary()}
                    </Typography>
                  </Paper>

                  {values.disposition.includes('Return') && (
                    <TextField
                      className={classes.item}
                      fullWidth
                      label="Select the return address"
                      name="returnTo"
                      onChange={onChange}
                      select
                      value={values.returnTo}
                      variant="outlined"
                    >
                      <MenuItem value="Pompano">
                        751 NW 33RD St Ste 130, Pompano Beach, FL, 33064-2053
                      </MenuItem>
                      <MenuItem value="new">Add new address</MenuItem>
                    </TextField>
                  )}

                  <TextField
                    className={classes.item}
                    fullWidth
                    label="Recall instructions"
                    multiline
                    name="instructions"
                    onChange={onChange}
                    rows={5}
                    value={values.instructions}
                    variant="outlined"
                  />
                </>
              )}

              <Dialog open={isAddressFormOpen}>
                <DialogTitle>
                  <Typography variant="h4">Add new address.</Typography>
                </DialogTitle>
                <DialogContent>
                  <Box
                    minHeight={300}
                    minWidth={500}
                    padding={2}
                  >
                    <Typography
                      align="center"
                      gutterBottom
                      variant="h4"
                    />
                    <Grid className={classes.boxFormInline}>
                      <FormGroup>
                        <TextField label="Door number" />
                      </FormGroup>
                      <FormGroup>
                        <TextField label="Street" />
                      </FormGroup>
                      <FormGroup>
                        <TextField label="City" />
                      </FormGroup>
                      <FormGroup>
                        <TextField label="Zip" />
                      </FormGroup>
                    </Grid>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setAddressFormOpen(false)}
                    variant="contained"
                  >
                    Back
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => setAddressFormOpen(false)}
                    variant="contained"
                  >
                    Next
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default RecallDispositionForm;
