import React from 'react';
import { useHistory } from 'react-router-dom';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton
} from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const RecallsTable = props => {
  const { className, recalls, ...rest } = props;
  const history = useHistory();

  const classes = useStyles();

  const handleStart = id => () => {
    return history.push(`/recalls/${id}/process`);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {recalls.map(recall => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={recall.id}
                  >
                    <TableCell>{recall.title}</TableCell>
                    <TableCell>{recall.type}</TableCell>
                    <TableCell>
                      <IconButton onClick={handleStart(recall.id)}>
                        <KeyboardArrowRightIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

RecallsTable.propTypes = {
  className: PropTypes.string,
  recalls: PropTypes.array.isRequired
};

export default RecallsTable;
