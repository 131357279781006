import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  Card,
  CardContent,
  MenuItem,
  Paper,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  item: {
    marginTop: '1rem',
    width: '100%'
  },
  sectionWrap: {
    display: 'flex',
    justifyContent: 'center'
  },
  section: {
    width: '60%'
  },
  card: {
    marginBottom: theme.spacing(4)
  },
  paper: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(4)
  }
}));
const RecallClassForm = ({ values, onChange }) => {
  const classes = useStyles();

  const getSummary = () =>
    values.class.includes(2)
      ? 'Recalls for products which might cause serious injury or temporary illness'
      : 'Recalls for products which cause serious injury or death.';

  return (
    <Grid>
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.sectionWrap}>
            <div className={classes.section}>
              <TextField
                className={classes.item}
                fullWidth
                label="Select the FDA Class"
                name="class"
                onChange={onChange}
                select
                value={values.class}
                variant="outlined"
              >
                <MenuItem value="FDA Class 1">FDA Class 1</MenuItem>
                <MenuItem value="FDA Class 2">FDA Class 2</MenuItem>
              </TextField>

              {values.class && (
                <Paper className={classes.paper}>
                  <Typography align="center">{values.class}</Typography>
                  <Typography
                    align="center"
                    style={{ marginTop: '8px' }}
                    variant="body1"
                  >
                    {getSummary()}
                  </Typography>
                </Paper>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default RecallClassForm;
