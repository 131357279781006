import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { RecallsTable } from './components';
import mockData from './data';
import { Fab } from '@material-ui/core';
import PlusIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  fab: {
    position: 'fixed',
    bottom: '20px',
    right: '20px'
  }
}));

const UserList = ({ history }) => {
  const classes = useStyles();

  const [recalls] = useState(mockData);

  const handleAdd = () => {
    history.push('/recalls/new');
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <RecallsTable recalls={recalls} />
        <Fab
          className={classes.fab}
          color="primary"
        >
          <PlusIcon onClick={handleAdd} />
        </Fab>
      </div>
    </div>
  );
};

export default UserList;
