import React, { useState, useMemo, useEffect } from 'react';
import clsx from 'clsx';
import uuid from 'uuid';

import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  Dialog,
  FormGroup,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Card,
  CardMedia,
  CardContent
} from '@material-ui/core';
import { default as ItemBox } from './Box';
import Item from './Item';

import { sortWith, descend, ascend, prop } from 'ramda';

import mockData from '../RecallsList/data';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  boxContainer: {
    marginBottom: theme.spacing(1),
    height: 'calc(100vh - 280px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    boxShadow: '1px 1px 0 0 rgba(0,0,0,.1)',
    marginLeft: '-30px',
    paddingLeft: '30px',
    marginRight: '-30px',
    paddingRight: '30px'
  },
  boxWrap: {
    justifyContent: 'start',
    display: 'flex',
    flexDirection: 'column'
  },
  section: {
    marginBottom: theme.spacing(1)
  },
  centered: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  fullWidth: {
    width: '100%'
  },
  boxFormInline: {
    display: 'flex',
    flexGrow: 1,
    marginBottom: theme.spacing(2),
    justifyContent: 'space-between',
    '& > *': {
      marginRight: theme.spacing(1)
    }
  },
  card: {
    display: 'flex',
    width: '100%',
    padding: theme.spacing(1)
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  media: {
    backgroundSize: 'contain',
    width: '150px'
  }
}));

const RecallsProcess = ({ match, history }) => {
  const classes = useStyles();
  const [recalls] = useState(mockData);
  const recall = useMemo(
    () => recalls.find(({ id }) => id === match.params.id),
    [match, recalls]
  );
  const [items] = useState(recall ? recall.items : []);

  const [boxes, setBoxes] = useState(
    JSON.parse(window.localStorage.getItem(`boxes_${recall.id}`))
  );

  const [isBoxFormOpen, setBoxFormOpen] = useState(false);

  const [flash, setFlash] = useState('');

  useEffect(() => {
    if (!boxes && items && items.length > 0) {
      const list = [];
      items.forEach(item => {
        if (!list[item.disposition]) {
          list[item.disposition] = {
            id: uuid(),
            name: `${item.disposition} - Box 1`,
            group: item.disposition,
            status: 'open',
            items: []
          };
        }
      });
      setBoxes(Object.values(list));
    }
  }, [items, boxes]);

  const closeBox = id => {
    const currentItems = [...boxes];
    currentItems.forEach(box => {
      if (box.id === id) {
        box.status = 'closed';
      }
    });
    setBoxes([...currentItems]);
  };

  // FIXME: this function is just crap, should rewrite
  const updateBox = ({ quantity, item }) => {
    const currentItems = [...boxes];
    let currentBoxId;
    let newBox = true;
    currentItems.forEach(box => {
      if (box.group === item.disposition && box.status !== 'closed') {
        let newItem = true;
        box.items.forEach(boxItem => {
          if (boxItem.upc === item.upc) {
            boxItem.quantity += parseInt(quantity || 0);
            newItem = false;
          }
        });
        if (newItem) {
          box.items.push({ ...item, quantity });
        }
        currentBoxId = box.id;
        newBox = false;
      }
    });
    if (newBox) {
      currentBoxId = uuid();
      const boxNumber =
        currentItems.filter(({ group }) => group === item.disposition).length +
        1;
      currentItems.push({
        id: currentBoxId,
        name: `${item.disposition} - Box ${boxNumber}`,
        group: item.disposition,
        status: 'open',
        items: [{ ...item, quantity }]
      });
    }
    setBoxes([...currentItems]);
    doFlash(currentBoxId);
  };

  const handleAdded = item => {
    items.forEach(currentItem => {
      if (currentItem.upc === item.upc) {
        updateBox({
          quantity: parseInt(item.quantity || 0),
          item: currentItem
        });
      }
    });
  };

  const doFlash = label => {
    setFlash(label);
    setTimeout(() => setFlash(''), 600);
  };

  const next = () => {
    window.localStorage.setItem(`boxes_${recall.id}`, JSON.stringify(boxes));
    history.push(`/recalls/${recall.id}/review`);
  };

  const handleNext = () => {
    if (recall.type !== 'external') {
      next();
    } else {
      setBoxFormOpen(true);
    }
  };

  const handleBoxNext = () => {
    next();
  };

  const handleBoxBack = () => {
    setBoxFormOpen(false);
  };

  const groupStatusSort = sortWith([
    descend(prop('status')),
    ascend(prop('group'))
  ]);

  const getSortedBoxes = () => groupStatusSort(Object.values(boxes));

  const handlItemQtyChange = item => event => {
    let list = [...boxes];

    list.forEach(box => {
      if (box.group !== item.disposition) return;

      const existing = box.items.find(({ upc }) => upc === item.upc);
      if (existing) {
        box.items.forEach(entry => {
          if (entry.upc === item.upc)
            entry.quantity = parseInt(event.target.value);
        });
      } else {
        box.items.push({
          ...item,
          quantity: parseInt(event.target.value)
        });
      }
    });
    setBoxes(list);
  };

  const renderBoxLayout = () => (
    <div className={classes.root}>
      <Grid
        className={clsx(classes.section, classes.centered)}
        container
        spacing={4}
      >
        <Grid
          className={classes.fullWidth}
          item
        >
          <Item
            onAdded={handleAdded}
            recall={recall}
          />
        </Grid>
      </Grid>
      <div className={classes.boxContainer}>
        <Grid
          className={classes.boxWrap}
          container
          spacing={4}
        >
          {boxes &&
            getSortedBoxes().map(box => (
              <Grid
                className={classes.fullWidth}
                item
                key={box.id}
              >
                <ItemBox
                  box={box}
                  flash={flash}
                  items={items}
                  onClose={closeBox}
                  recall={recall}
                />
              </Grid>
            ))}
        </Grid>
      </div>
      <Grid container>
        <Grid
          alignContent="center"
          item
          style={{ width: '100%', textAlign: 'right', paddingTop: '5px' }}
        >
          <Button
            className={classes.button}
            color="primary"
            onClick={handleNext}
            variant="contained"
          >
            Next
          </Button>
        </Grid>
      </Grid>
      <Dialog open={isBoxFormOpen}>
        <DialogTitle>
          <Typography variant="h4">Box information</Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            minHeight={300}
            padding={2}
          >
            <Typography
              align="center"
              gutterBottom
              variant="h4"
            >
              {boxes && boxes[0] ? boxes[0].name : ''}
            </Typography>
            <Grid className={classes.boxFormInline}>
              <FormGroup>
                <TextField
                  helperText="Length"
                  label="L"
                />
              </FormGroup>
              <FormGroup>
                <TextField
                  helperText="Width"
                  label="W"
                />
              </FormGroup>
              <FormGroup>
                <TextField
                  helperText="Height"
                  label="H"
                />
              </FormGroup>
            </Grid>
            <Grid className={classes.boxFormInline}>
              <FormGroup>
                <TextField label="Weight" />
              </FormGroup>
            </Grid>

            <Grid className={classes.boxFormInline}>
              <FormControlLabel
                control={<Checkbox
                  checked
                  color="primary"
                  name="checkedB"
                         />}
                label="All the boxes are of same size & weight."
              />
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleBoxBack}
            variant="contained"
          >
            Back
          </Button>
          <Button
            color="primary"
            onClick={handleBoxNext}
            variant="contained"
          >
            Next
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  const renderSimpleLayout = () => (
    <div className={classes.root}>
      <Grid
        container
        spacing={2}
      >
        {items.map(item => (
          <Grid
            className={classes.fullWidth}
            item
          >
            <Card
              className={classes.card}
              id={item.upc}
            >
              <CardMedia
                className={classes.media}
                image={item.image}
              />
              <CardContent className={classes.fullWidth}>
                <Grid
                  className={classes.item}
                  container
                  spacing={4}
                >
                  <Grid
                    item
                    xs={6}
                  >
                    <Typography
                      color="textSecondary"
                      gutterBottom
                    >
                      {item.upc}
                    </Typography>
                    <Typography component="h2">{item.name}</Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Quantity"
                      onChange={handlItemQtyChange(item)}
                      value={
                        boxes
                          ? boxes
                            .find(box => box.group === item.disposition)
                            .items.find(entry => entry.upc === item.upc)
                              ?.quantity
                          : ''
                      }
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Grid container>
        <Grid
          alignContent="center"
          item
          style={{ width: '100%', textAlign: 'right', paddingTop: '15px' }}
        >
          <Button
            className={classes.button}
            color="primary"
            onClick={handleNext}
            variant="contained"
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  return recall.type === 'simple' ? renderSimpleLayout() : renderBoxLayout();
};

export default RecallsProcess;
