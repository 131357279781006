import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import {
  Card,
  CardContent,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(1)
  }
}));

const Item = ({ recall, onAdded }) => {
  const classes = useStyles();

  const [values, setValues] = useState({
    upc: '',
    quantity: ''
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter' && values.upc && values.quantity)
      return handleSubmit();
  };

  const handleSubmit = () => {
    const item = recall.items.find(({ upc }) => upc === values.upc);
    if (item) {
      onAdded(values);
      setValues({
        upc: '',
        quantity: ''
      });
    } else {
      alert('NOF');
    }
  };

  return (
    <Card className={classes.root}>
      <form
        autoComplete="off"
        noValidate
      >
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid item>
              <TextField
                helperText="Please enter the product upc"
                label="UPC"
                margin="dense"
                name="upc"
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                required
                value={values.upc}
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <TextField
                label="Quantity"
                margin="dense"
                name="quantity"
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                required
                type="number"
                value={values.quantity}
                variant="outlined"
              />
            </Grid>

            <Grid item>
              <TextField
                label="LOT numbers"
                margin="dense"
                name="lot"
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                value={values.lot}
                variant="outlined"
              />
            </Grid>

            <Grid item>
              <Button
                className={classes.button}
                color="primary"
                onClick={handleSubmit}
                variant="contained"
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
      </form>
    </Card>
  );
};

export default Item;
