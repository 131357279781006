import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField, Card, CardContent } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  item: {
    marginTop: '1rem'
  },
  sectionWrap: {
    display: 'flex',
    justifyContent: 'center'
  },
  section: {
    maxWidth: '60%'
  },
  card: {
    marginBottom: theme.spacing(4)
  }
}));
const NoticeForm = ({ values, onChange }) => {
  const classes = useStyles();

  return (
    <Grid>
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.sectionWrap}>
            <div className={classes.section}>
              <TextField
                className={classes.item}
                fullWidth
                label="Give this recall a title."
                name="title"
                onChange={onChange}
                value={values.title}
                variant="outlined"
              />

              <TextField
                className={classes.item}
                fullWidth
                label="Give an explanation of the reason of this recall."
                multiline
                name="description"
                onChange={onChange}
                rows={3}
                value={values.description}
                variant="outlined"
              />

              <TextField
                className={classes.item}
                fullWidth
                label="Recall web url (Optional)."
                name="url"
                onChange={onChange}
                type="url"
                value={values.url}
                variant="outlined"
              />

              <TextField
                className={classes.item}
                fullWidth
                label="Your internal recall reference number (Optional)."
                name="referenceNumber"
                onChange={onChange}
                type="url"
                value={values.referenceNumber}
                variant="outlined"
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default NoticeForm;
