const items = [
  {
    id: 1,
    name: 'Motts Assorted Fruit Snacks Value Pack',
    upc: '016000494329',
    disposition: 'Hold at reclaim',
    image: 'https://images.barcodelookup.com/3181/31819855-1.jpg'
  },
  {
    id: 2,
    name:
      'Maybelline Garnier 1039369 12 Oz Garnier Fructis Sleek & Shine Conditioner',
    upc: '603084491261',
    disposition: 'Donate at store',
    image: 'https://images.barcodelookup.com/2278/22789913-1.jpg'
  },
  {
    id: 3,
    name:
      'Himalaya Herbal Baby Lotion Nourishes & Moisturizes Makes Skin Soft and Smooth Almond & Olive Oil Ext 100ml',
    upc: '8901138511463',
    disposition: 'Return to reclaim',
    image: 'https://images.barcodelookup.com/4444/44440054-2.jpg'
  },
  {
    id: 4,
    name: 'Baby Dove Rich Moisture Tip to Toe Baby Wash, 20 Oz',
    upc: '997963853449',
    disposition: 'Destroy at store',
    image: 'https://images.barcodelookup.com/16823/168230850-1.jpg'
  }
];

export default [
  {
    id: '11622569-66f5-4669-ad6c-ff9ed5a0a17a',
    title: 'A simple recall',
    type: 'simple',
    items
  },
  {
    id: '54d89cef-2979-43da-9c45-4059edadd7ee',
    title: 'Recall with in-house shipping.',
    type: 'in-house',
    items
  },
  {
    id: 'fa0007dc-5cc1-483a-8c07-50cedd611989',
    title: 'Recall with external shipping.',
    type: 'external',
    items
  }
];
