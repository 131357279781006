import React, { useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Typography,
  Paper,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  CardActions,
  TextField,
  FormGroup,
  Box,
  Button,
  AppBar,
  Tab,
  Tabs
} from '@material-ui/core';

import TabPanel from './TabPanel';
import TickIcon from '@material-ui/icons/CheckCircle';
import PrintIcon from '@material-ui/icons/Print';

import mockData from '../RecallsList/data';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  card: {
    display: 'flex',
    width: '100%',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  media: {
    backgroundSize: 'contain',
    width: '150px'
  },
  summary: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    justifyContent: 'flex-end',
    display: 'flex',
    flexDirection: 'column'
  },
  item: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  controlWrap: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(4),
    padding: theme.spacing(1)
  },
  control: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  button: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  done: {
    padding: '25%'
  },
  summaryItem: {
    display: 'flex',
    '& > :first-child': {
      width: '120px'
    },
    '& > :last-child': {
      width: '80px',
      textAlign: 'right'
    }
  },
  boxes: {
    flexGrow: 1
  },
  box: {
    maxWidth: '33.3%'
  }
}));

const RecallsProcessReview = ({ match }) => {
  const [submitted, setSubmitted] = useState(false);

  const [recalls] = useState(mockData);

  const recall = useMemo(
    () => recalls.find(({ id }) => id === match.params.id),
    [match, recalls]
  );

  const [boxes] = useState(
    JSON.parse(window.localStorage.getItem(`boxes_${recall.id}`))
  );

  const [currentTab, setCurrentTab] = useState(
    recall.type === 'external' ? 1 : 0
  );

  const [error, setError] = useState(false);

  const [values, setValues] = useState({ sign: '' });

  const classes = useStyles();

  const items = useMemo(() => {
    let list = {};
    boxes.forEach(box => {
      box.items.forEach(item => {
        if (!list[item.upc]) {
          list[item.upc] = item;
        } else {
          list[item.upc].quantity += item.quantity;
        }
      });
    });
    return Object.values(list);
  }, [boxes]);

  const itemsCount = useMemo(
    () => items.reduce((sum, item) => sum + item.quantity, 0),
    [items]
  );

  const handleChange = event => {
    setError(false);
    setValues({ [event.target.name]: event.target.value });
  };

  const handleSubmit = () => {
    if (values.sign) {
      setSubmitted(true);
      window.localStorage.removeItem(`boxes_${recall.id}`);
    } else {
      setError(true);
    }
  };

  const handleTabChange = (event, value) => setCurrentTab(value);

  if (submitted) {
    return (
      <Grid className={classes.done}>
        <Typography
          align="center"
          style={{ width: '100%', textAlign: 'center', color: 'green' }}>
          <TickIcon />
        </Typography>
        <Typography style={{ width: '100%', textAlign: 'center' }} variant="h2">
          Thank you!
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid className={classes.root}>
      <Paper className={classes.root}>
        <Typography align="center" gutterBottom variant="h2">
          Recall summary
        </Typography>

        {recall.type !== 'simple' && (
          <AppBar position="static">
            <Tabs
              aria-label="Review"
              onChange={handleTabChange}
              value={currentTab}>
              <Tab label="Item-wise" />
              <Tab label="Box-wise" />
            </Tabs>
          </AppBar>
        )}

        <TabPanel index={0} value={currentTab}>
          <Grid>
            {items.map(item => (
              <Card className={classes.card} id={item.upc} key={item.upc}>
                <CardMedia className={classes.media} image={item.image} />
                <CardContent className={classes.item}>
                  <Grid item>
                    <Typography color="textSecondary" gutterBottom>
                      {item.upc}
                    </Typography>
                    <Typography component="h2">{item.name}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h5">
                      Units scanned: {item.quantity}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="caption">
                      ${item.quantity * 2} credit
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>
            ))}
          </Grid>
        </TabPanel>
        <TabPanel index={1} value={currentTab}>
          <Grid className={classes.boxes} container spacing={2}>
            {boxes
              .filter(({ items }) => items.length)
              .map(box => (
                <Grid className={classes.box} item key={box.id}>
                  <Card>
                    <CardActionArea>
                      <CardContent>
                        <Typography component="h2" gutterBottom variant="h5">
                          {box.name}
                        </Typography>
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="caption">
                          {box.group}
                        </Typography>
                        <br />
                        <Typography
                          color="textSecondary"
                          component="p"
                          variant="body1">
                          Total quantity:{' '}
                          {box.items.reduce(
                            (sum, item) => sum + item.quantity,
                            0
                          )}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    {recall.type !== 'simple' && (
                      <CardActions>
                        <Button color="primary" size="small">
                          <PrintIcon /> Shipping label
                        </Button>
                        <Button color="primary" size="small">
                          <PrintIcon /> Packing slip
                        </Button>
                      </CardActions>
                    )}
                  </Card>
                </Grid>
              ))}
          </Grid>
        </TabPanel>

        <Paper className={classes.controlWrap}>
          <Box className={classes.summary}>
            <Grid className={classes.summaryItem}>
              <Typography variant="h5">Total units:</Typography>
              <Typography variant="h5">{itemsCount}</Typography>
            </Grid>
            <Grid className={classes.summaryItem}>
              <Typography variant="h5">Total credit:</Typography>
              <Typography variant="h5">${itemsCount * 2}</Typography>
            </Grid>
          </Box>
          <Typography variant="h4">Electronic signature required</Typography>
          <Grid className={classes.control} container>
            <Grid item>
              <FormGroup>
                <TextField
                  error={error}
                  helperText="Please place your name below in order to signify that you have processed this recall in the manner prescribed."
                  label="Electronic signature"
                  name="sign"
                  onChange={handleChange}
                  value={values.sign}
                />
              </FormGroup>
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                color="primary"
                onClick={handleSubmit}
                variant="contained">
                Submit
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Paper>
    </Grid>
  );
};

export default RecallsProcessReview;
